<template>
  <div id="order-view">
    <section>
      <validation-observer #default="{ invalid }" ref="orderViewObserver">
        <b-row class="invoice-preview">
          <b-col cols="12" xl="9">
            <supply-document
              id="order"
              :edition="quoteEditable"
              :loading="quoteLoading"
              :supply="quote"
              :invoice-configuration="quoteSelected.invoiceConfiguration"
              :show-cancellation-conditions="showCancellationConditions"
              @updateSupply="saveQuote"
              @supplyTouched="quoteTouched = $event"
            />
          </b-col>
          <b-col cols="12" xl="3">
            <OrderViewActionsManager :disabled="invalid || (!$can('TRIP_ACCOUNTING_EDIT') && !$can('TRIP_ACCOUNTING_DELETE'))" />
            <OrderViewStates v-if="showStates" />
            <component :is="additionalComponent" />
          </b-col>
        </b-row>
      </validation-observer>
      <b-row v-if="!quoteSelected.offerOrCommissionQuote">
        <b-col>
          <OrderViewHistory />
        </b-col>
        <b-col>
          <OrderViewServices v-if="quote.id && !quoteLoading" :services-edition-dates="servicesEditionDates" :services-edition-status="servicesEditionStatus" />
        </b-col>
        <b-col>
          <OrderViewCancellationPolicies id="order-view-cancellation-policies-bloc" />
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'

import { patchQuoteRequest } from '@/request/globalApi/requests/quoteRequests'

import SupplyDocument from '@/components/SupplyDocument.vue'
import OrderViewActionsManager from '@/views/order/OrderViewActionsManager.vue'

export default {
  name: 'OrderView',

  components: {
    SupplyDocument,
    OrderViewHistory: () => import('@/views/order/OrderViewHistory.vue'),
    OrderViewServices: () => import('@/views/order/OrderViewServices.vue'),
    OrderViewActionsManager,
    OrderViewStates: () => import('@/views/order/OrderViewStates.vue'),
    OrderViewCancellationPolicies: () => import('@/views/order/OrderViewCancellationPolicies.vue'),
    OrderViewAdditionalPaymentTerms: () => import('@/views/order/OrderViewAdditionalPaymentTerms.vue'),
    OrderViewAdditionalClaim: () => import('@/views/order/OrderViewAdditionalClaim.vue'),
    OrderViewAdditionalCancelled: () => import('@/views/order/OrderViewAdditionalCancelled.vue'),
  },

  computed: {
    ...mapState('quote', ['isQuoteOwner', 'isQuoteClient', 'quoteSelected']),
    ...mapFields('quote', ['quoteSelected.quote', 'quoteEditable', 'quoteTouched', 'quoteLoading', 'validateQuote', 'quoteValid']),
    ...mapGetters('quote', ['isQuoteCancelledOrRefused', 'quoteTitle']),
    ...mapState('auth', ['selectedOrganization']),

    additionalComponent() {
      if (this.quoteSelected.offerOrCommissionQuote) return ''

      const { state, cancelled, cancellationPoliciesApplied } = this.quoteSelected.quote
      if (this.$can('TRIP_ACCOUNTING_EDIT') && cancelled && cancellationPoliciesApplied === null && this.isQuoteOwner && !(state === 'awaiting_customer_validation' || state === 'awaiting_for_quotation')) return 'OrderViewAdditionalCancelled'
      if (!this.isQuoteCancelledOrRefused && this.quoteEditable && (state === 'awaiting_customer_validation' || state === 'awaiting_for_quotation')) {
        return 'OrderViewAdditionalPaymentTerms'
      }
      if (!this.isQuoteCancelledOrRefused && (state === 'claimed' || (state === 'delivered' && this.quoteSelected?.quote?.claim?.closed))) {
        return 'OrderViewAdditionalClaim'
      }
      return ''
    },

    // Issue #MFC2-781
    servicesEditionDates() {
      return this.$can('TRIP_ACCOUNTING_EDIT')
        && !this.isQuoteCancelledOrRefused
        && !this.quoteTouched
        && this.quote?.state !== 'delivered'
        && this.quote?.state !== 'claimed'
        && this.selectedOrganization.id === this.quote?.organization.id
    },

    // Issue #MFC2-781
    servicesEditionStatus() {
      return !this.isQuoteCancelledOrRefused
        && !this.quoteTouched
        && this.quote?.state !== 'delivered'
        && this.quote?.state !== 'awaiting_for_quotation'
        && this.quote?.state !== 'awaiting_customer_validation'
        && this.quote?.state !== 'claimed'
        && (
          (this.$can('TRIP_ACCOUNTING_EDIT') && this.selectedOrganization.id === this.quote.organization.id)
          || this.selectedOrganization.operator
      )
    },

    showStates() {
      return (
        (this.quoteSelected.quote.state && !this.quoteSelected.quote.cancelled)
        || (this.quoteSelected.paymentIntent && !this.quoteSelected.quote.cancelled)
        || (this.quoteSelected.quote.cancelled && this.quoteSelected.quote.cancellationPoliciesApplied !== null)
        || (this.quoteSelected.quote.cancelled && this.isQuoteClient)
        || (this.selectedOrganization.operator && this.quoteSelected.quote.organization.id !== this.selectedOrganization.id)
      )
    },

    showCancellationConditions() {
      return this.quoteSelected.quote.cancelled && this.quoteSelected.quote.cancellationPoliciesApplied
    },
  },

  watch: {
    validateQuote: {
      handler() {
        this.$refs.orderViewObserver.validate().then(success => {
          this.quoteValid = success
          this.validateQuote = false
        })
      },
    },
    '$route.params.id': {
      handler() {
        this.refresh(this.$route.params.id)
        if (this.$route.params.quoteEditable && this.$can('TRIP_ACCOUNTING_EDIT')) {
          this.quoteEditable = this.$route.params.quoteEditable
        }
      },
    },
  },

  mounted() {
    if (this.$route.params.id) {
      this.quoteLoading = true
      this.fetchQuote({ id: this.$route.params.id })
        .then(() => {
          this.defineQuoteEdition()
        })
        .finally(() => {
          this.quoteLoading = false
        })
    }
  },

  methods: {
    ...mapActions('quote', ['resetState', 'clearQuoteSelected', 'fetchQuote']),

    defineQuoteEdition() {
      this.quoteEditable = false
      if (this.$can('TRIP_ACCOUNTING_EDIT') && this.isQuoteOwner && this.quoteSelected.quote.state === 'awaiting_for_quotation' && !this.quoteSelected.quote.refused && !this.quoteSelected.quote.cancelled && !this.quoteSelected.quote.cancelledDraft) this.quoteEditable = true
      if (this.$can('TRIP_ACCOUNTING_EDIT') && this.isQuoteOwner && this.quoteSelected.quote.state !== 'awaiting_for_quotation' && this.quoteSelected.quote.cancelled && this.quoteSelected.quote.cancellationPoliciesApplied === null) this.quoteEditable = true
    },

    saveQuote(quoteToSave) {
      this.quote = quoteToSave
      this.quoteLoading = true
      patchQuoteRequest(this.quoteSelected.quote, this.$store.getters['quote/quoteTitleAndRef'])
        .then(() => {
          this.fetchQuote({ refresh: true, id: this.quoteSelected.quote.id }).finally(() => {
            this.quoteLoading = false
          })
        })
        .catch(err => {
          err && (this.quoteLoading = false)
        })
    },
    refresh(quoteId) {
      this.quoteLoading = true
      this.fetchQuote({ refresh: true, id: quoteId }).finally(() => {
        this.quoteLoading = false
      })
    },
  },

  beforeRouteLeave(to, from, next) {
    this.clearQuoteSelected()
    if (to.matched[0].name !== 'OrdersManager') {
      this.resetState()
    }
    next()
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
</style>
